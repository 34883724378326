import * as React from 'react';
import { useHistory } from 'react-router-dom';
import CognitoClient from 'amazon-cognito-identity-js/src/Client';
import { changeHandler, validationHandler, validationResetHandler } from '../lib/form';
import { getPoolId, getPublicCognitoClientId } from '../lib/global';
import { getUserContextData } from '../lib/auth';

import {
    ScreenWrapper,
    InputWrapper,
    NavigationHeader,
    BackIcon,
    BackIconPath,
    BackButtonWrapper,
    Input,
    Label,
    ErrorContainer,
    Error,
    Submit,
    TextHeader,
    VerificationText,
    SubmitWrapper
} from '../styles';

const ForgottenPassword = ({ clientId, location }) => {
    const prefilledEmail = location.state && location.state.email;

    const history = useHistory();
    const [complete, setComplete] = React.useState(false);
    const [email, setEmail] = React.useState(prefilledEmail || null);
    const [emailError, setEmailError] = React.useState(false);
    const [error, setError] = React.useState(null);

    const handleChange = changeHandler({ email: setEmail });
    const handleValidation = validationHandler({ email: setEmailError });
    const handleValidationReset = validationResetHandler({ email: setEmailError });

    const userPoolId = getPoolId();
    const cognitoClientId = getPublicCognitoClientId();

    const congitoClient = new CognitoClient(userPoolId.split('_')[0]);

    const resetErrors = () => {
        setEmailError(false);
    };

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            await fetch(`/auth/password-reset`, {
                method: 'POST',
                body: JSON.stringify({ email: email.trim().toLowerCase() })
            });
        } catch (err) {
            // we don't care about the result of this operation as it always successed
        }

        try {
            const username = email.trim().toLowerCase();

            const jsonReq = {
                ClientId: cognitoClientId,
                Username: username,
                UserContextData: getUserContextData(username, userPoolId, cognitoClientId)
            };

            // Will always succed when using a client with default "Prevent User Existence Errors" enabled
            await congitoClient.promisifyRequest('ForgotPassword', jsonReq);

            setComplete(true);
        } catch (err) {
            // this is still success as far as the user is concerned. This condition being possible
            // when "Prevent User Existence Errors" is disabled
            if (err.name === 'UserNotFoundException') {
                setComplete(true);
                return;
            }
            setError('An unexpected error occurred. Please try again');
        }
    };

    const goHome = () => history.push(`/${window.location.search}`);
    const goBack = () => history.goBack();

    const resetError = () => setError(null);

    return (
        <ScreenWrapper>
            {error && (
                <ErrorContainer>
                    <Error onClick={resetError}>{error}</Error>
                </ErrorContainer>
            )}
            {complete && (
                <div>
                    <TextHeader>
                        <span>A unique code has been sent</span>
                    </TextHeader>
                    <VerificationText>
                        <span>This may take a few moments. Check your junk mail if you can't see it.</span>
                    </VerificationText>
                    <SubmitWrapper>
                        <Submit onClick={goHome} type="button" value=" Go back" />
                    </SubmitWrapper>
                </div>
            )}
            {!complete && (
                <div>
                    <NavigationHeader>
                        <BackButtonWrapper onClick={goBack}>
                            <BackIcon viewBox="0 0 20 20">
                                <BackIconPath />
                            </BackIcon>
                        </BackButtonWrapper>
                    </NavigationHeader>
                    <TextHeader>
                        <span>Let's reset your password</span>
                    </TextHeader>
                    <VerificationText>
                        <span>
                            Please enter the e-mail address for your account. A unique code will be emailed to you. You
                            can then set a new password.
                        </span>
                    </VerificationText>
                    <form onSubmit={handleSubmit} onInvalid={handleValidation} onChange={handleValidationReset}>
                        <InputWrapper>
                            <Label isError={emailError} htmlFor="email">
                                E-mail:
                            </Label>
                            <Input isError={emailError} name="email" onChange={handleChange} required type="email" />
                        </InputWrapper>
                        <SubmitWrapper>
                            <Submit onClick={resetErrors} type="submit" value="Send code" />
                        </SubmitWrapper>
                    </form>
                </div>
            )}
        </ScreenWrapper>
    );
};


export default ForgottenPassword;