import * as React from 'react';
import { useHistory } from 'react-router-dom';
import CognitoClient from 'amazon-cognito-identity-js/src/Client';
import { getPoolId, getPublicCognitoClientId } from '../lib/global';
import { getUserContextData } from '../lib/auth';
import {
    ScreenWrapper,
    ErrorContainer,
    Error,
    TextHeader,
    VerificationText,
    SubmitWrapper,
    Submit,
    NavigationHeader,
    BackIcon,
    BackIconPath,
    BackButtonWrapper
} from '../styles';

const PasswordRestRequired = ({ clientId, location }) => {
    const email = location.state && location.state.email;

    const history = useHistory();
    const [complete, setComplete] = React.useState(false);
    const [error, setError] = React.useState(null);

    const userPoolId = getPoolId();
    const cognitoClientId = getPublicCognitoClientId();
    const congitoClient = new CognitoClient(userPoolId.split('_')[0]);

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            const username = email.trim().toLowerCase();

            const jsonReq = {
                ClientId: cognitoClientId,
                Username: username,
                UserContextData: getUserContextData(username, userPoolId, cognitoClientId)
            };

            // Will always succed when using a client with default "Prevent User Existence Errors" enabled
            await congitoClient.promisifyRequest('ForgotPassword', jsonReq);

            setComplete(true);
        } catch (err) {
            // this is still success as far as the user is concerned. This condition being possible
            // when "Prevent User Existence Errors" is disabled
            if (err.name === 'UserNotFoundException') {
                setComplete(true);
                return;
            }
            setError('An unexpected error occurred. Please try again');
        }
    };

    const goHome = () => history.push(`/${window.location.search}`);
    const goBack = () => history.goBack();

    const resetError = () => setError(null);

    return (
        <ScreenWrapper>
            {error && (
                <ErrorContainer>
                    <Error onClick={resetError}>{error}</Error>
                </ErrorContainer>
            )}
            {complete && (
                <div>
                    <TextHeader>
                        <span>A unique code has been sent</span>
                    </TextHeader>
                    <VerificationText>
                        <span>This may take a few moments. Check your junk mail if you can't see it.</span>
                    </VerificationText>
                    <SubmitWrapper>
                        <Submit onClick={goHome} type="button" value=" Go back" />
                    </SubmitWrapper>
                </div>
            )}
            {!complete && (
                <div>
                    <NavigationHeader>
                        <BackButtonWrapper onClick={goBack}>
                            <BackIcon viewBox="0 0 20 20">
                                <BackIconPath />
                            </BackIcon>
                        </BackButtonWrapper>
                    </NavigationHeader>
                    <TextHeader>
                        <span>Let's reset your password</span>
                    </TextHeader>
                    <VerificationText>
                        <span>
                            Click here to send a password reset message. A unique code will be emailed to you. You can
                            then set a new password.
                        </span>
                    </VerificationText>
                    <form onSubmit={handleSubmit}>
                        <SubmitWrapper>
                            <Submit type="submit" value="Send code" />
                        </SubmitWrapper>
                    </form>
                </div>
            )}
        </ScreenWrapper>
    );
};

export default PasswordRestRequired;