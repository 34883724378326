import url from 'url';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { AuthenticationHelper } from 'amazon-cognito-identity-js';

import { createLargeAValue, createChallengeResponses, getUserContextData } from '../lib/auth';
import BigInteger from 'amazon-cognito-identity-js/es/BigInteger';
import { changeHandler, validationHandler, validationResetHandler } from '../lib/form';
import { getClientId, getRedirectURL, getPoolId, getCognitoClientId, getState } from '../lib/global';
import {
    LoginTextHeader,
    ScreenWrapper,
    InputWrapper,
    ErrorContainer,
    Error,
    Input,
    Label,
    SubmitWrapper,
    Submit,
    FormLink,
    ExternalLink,
    Logo
} from '../styles';

const Login = ({ clientId }) => {
    const history = useHistory();
    const [username, setUsername] = React.useState(null);
    const [usernameError, setUsernameError] = React.useState(false);
    const [password, setPassword] = React.useState(null);
    const [passwordError, setPasswordError] = React.useState(false);
    const [loginError, setLoginError] = React.useState(null);
    const [loginEnabled, setLoginEnabled] = React.useState(true);
    const [inputsDisabled, setInputsDisabled] = React.useState(false);

    const errorHandlers = { username: setUsernameError, password: setPasswordError };

    const handleChange = changeHandler({ username: setUsername, password: setPassword });
    const handleValidation = validationHandler(errorHandlers);
    const handleValidationReset = validationResetHandler(errorHandlers);

    const resetErrors = () => {
        setUsernameError(false);
        setPasswordError(false);
        setLoginError(null);
    };

    const handleSubmit = async event => {
        event.preventDefault();

        setLoginEnabled(false);
        setInputsDisabled(true);

        const client = getClientId();
        const userPoolId = getPoolId();
        const cognitoClientId = getCognitoClientId();
        const state = getState();

        try {
            // lowercase email/username for further internal use
            const cleanUsername = username.trim().toLowerCase();
            let initiateUrl;
            if (state){
                initiateUrl = url.format({ pathname: '/auth/login', query: { client, state } });
            }else {
                initiateUrl = url.format({ pathname: '/auth/login', query: { client } });
            }

            const authenticationHelper = new AuthenticationHelper(userPoolId.split('_')[1]);

            const initRes = await fetch(initiateUrl, {
                method: 'POST',
                body: JSON.stringify({
                    AuthParameters: {
                        USERNAME: cleanUsername,
                        SRP_A: await createLargeAValue(authenticationHelper)
                    },
                    UserContextData: getUserContextData(cleanUsername, userPoolId, cognitoClientId)
                })
            });

            if (initRes.status === 400) {
                const body = await initRes.json();
                if (body.error === 'MALFORMED_REQUEST') {
                    return setLoginError('The credentials supplied are an invalid format.');
                }
                // this shouldn't happen but nevertheless
                return setLoginError('An unexpected error occurred. Please try again');
            }

            // cognito rate-limiting response happens here
            if (initRes.status === 401) {
                const body = await initRes.json();
                if (body.error === 'TOO_MANY_ATTEMPTS') {
                    return setLoginError('Too many incorrect login attempts. Please try again later.');
                }
                // this shouldn't happen but nevertheless
                return setLoginError('An unexpected error occurred. Please try again');
            }

            const { ChallengeParameters, Session, ChallengeName } = await initRes.json();

            const ChallengeResponses = await createChallengeResponses(
                BigInteger,
                authenticationHelper,
                password,
                ChallengeParameters
            );

            // TODO: move redirect param to later requests
            const challengeRes = await fetch(initiateUrl, {
                method: 'POST',
                body: JSON.stringify({
                    ChallengeResponses,
                    ChallengeName,
                    Session,
                    UserContextData: getUserContextData(cleanUsername, userPoolId, cognitoClientId),
                    redirect: getRedirectURL()
                })
            });

            if (challengeRes.status === 400) {
                const body = await challengeRes.json();
                if (body.error === 'DEVICE_NOT_FOUND') {
                    // We should received a clear device cookie header as well
                    // TODO: protection against this getting stuck in a loop?
                    console.log('DEVICE_NOT_FOUND');
                    return setTimeout(handleSubmit, 100, event);
                }
            }

            // Password Reset Required - send the user to the reset screen
            if (challengeRes.status === 401 && challengeRes.headers.has('x-hive-sso-password-reset-required')) {
                // If the username is not also the email address - then skip this!
                // Should only apply to test users
                if (cleanUsername.indexOf('@') > 0) {
                    history.push(`/password-reset-required${window.location.search}`, { email: cleanUsername });
                } else {
                    history.push(`/forgotten-password${window.location.search}`, { email: cleanUsername });
                }

                return;
            }

            const body = await challengeRes.json();

            if (body.ChallengeName === 'MFA_SETUP') {
                setLoginError('Your account must have a verified phone number and Two Factor Authentication (2FA) enabled before you can login');
                return;
            }

            // TODO: instead switch based upon response ChallengeName rather than 401
            // MFA Challenge - send the user to the verify screen
            if (body.ChallengeName === 'SMS_MFA' || body.ChallengeName === 'SOFTWARE_TOKEN_MFA') {
                history.push(`/verify${window.location.search}`, {
                    ChallengeParameters: body.ChallengeParameters,
                    Session: body.Session,
                    ChallengeName: body.ChallengeName,
                    USERNAME: body.USERNAME, // this is the uuid instead of the alias
                    username: cleanUsername,
                    password
                });
                return;
            }

            // Invalid username/password or cognito rate limiting
            if (challengeRes.status === 401) {
                if (body.error === 'TOO_MANY_ATTEMPTS') {
                    return setLoginError('Too many incorrect login attempts. Please try again later.');
                }
                setLoginError('Username/Password not recognised');
                return;
            }

            // All is well - send the user to the client site
            if (challengeRes.status === 200) {
                window.location.href = body.location;
                return;
            }

            // Something else went wrong
            setLoginError('An unexpected error occurred. Please try again');
        } catch (err) {
            console.error(err);
            setLoginError('An unexpected error occurred. Please try again');
        } finally {
            setInputsDisabled(false);
            setLoginEnabled(true);
        }
    };

    const resetError = () => setLoginError(null);

    return (
        <ScreenWrapper>
            <Logo />
            {loginError && (
                <ErrorContainer>
                    <Error onClick={resetError}>{loginError}</Error>
                </ErrorContainer>
            )}
            <LoginTextHeader>
                <span>Log into your account</span>
            </LoginTextHeader>
            <form onSubmit={handleSubmit} onInvalid={handleValidation} onChange={handleValidationReset}>
                <InputWrapper>
                    <Label isError={usernameError} className="input-label" htmlFor="username">
                        Username:
                    </Label>
                    <Input
                        isError={usernameError}
                        name="username"
                        onChange={handleChange}
                        type="text"
                        required="true"
                        disabled={inputsDisabled}
                    />
                </InputWrapper>
                <InputWrapper>
                    <Label isError={passwordError} htmlFor="password">
                        Password:
                    </Label>
                    <Input
                        isError={passwordError}
                        name="password"
                        onChange={handleChange}
                        type="password"
                        required="true"
                        disabled={inputsDisabled}
                    />
                </InputWrapper>
                <FormLink to={`/forgotten-password${window.location.search}`}>Forgot password</FormLink>
                <ExternalLink href="https://www.hivehome.com/register">Create account</ExternalLink>
                <SubmitWrapper>
                    <Submit
                        onClick={resetErrors}
                        disabled={usernameError || passwordError || !username || !password || !loginEnabled}
                        type="submit"
                        value="Log in"
                    />
                </SubmitWrapper>
            </form>
        </ScreenWrapper>
    );
};


export default Login;
