import * as React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Main, Container, DesktopContainer, BorderContainer, Body, ErrorContainer, Error } from './styles';

import LoginScreen from './screens/login';
import VerifyScreen from './screens/verify';
import ForgottenPasswordScreen from './screens/forgotten-password';
import PasswordResetRequiredScreen from './screens/password-reset-required';
import ResetPasswordScreen from './screens/reset-password';
import ConfirmLoginAttempt from './screens/confirm-login-attempt';
import DenyLoginAttempt from './screens/deny-login-attempt';

const App = () => {
    const [alert, setAlert] = React.useState(null);
    //TODO: Replace this with a proper implementation
    React.useEffect(() => {
        fetch('https://hive-app-service-text.hivehome.net/prod/gb.json').then(async res => {
            if (res.status >= 200 && res.status <= 299) {
                const body = await res.json();
                setAlert(body);
            }
        });
    }, []);

    return (
        <Main>
            <Container>
                <Body>
                    {alert && (
                        <ErrorContainer>
                            <Error>
                                <h4>{alert.message}</h4>
                                <span>{alert.detail}</span>
                            </Error>
                        </ErrorContainer>
                    )}
                    <BrowserRouter>
                        <Switch>
                            <Route path="/confirm-login-attempt" exact component={ConfirmLoginAttempt} />
                            <Route path="/deny-login-attempt" exact component={DenyLoginAttempt} />
                            <Route path="/password-reset-required" exact component={PasswordResetRequiredScreen} />
                            <Route path="/forgotten-password" exact component={ForgottenPasswordScreen} />
                            <Route path="/reset-password" component={ResetPasswordScreen} />
                            <Route path="/verify" component={VerifyScreen} />
                            <Route path="/index" component={LoginScreen} />
                            <Route component={LoginScreen} />
                        </Switch>
                    </BrowserRouter>
                </Body>
            </Container>
            <DesktopContainer>
                <BorderContainer />
            </DesktopContainer>
        </Main>
    );
};

export default App;