import { promisify } from 'es6-promisify';
import { Buffer } from 'buffer';

import { DateHelper } from 'amazon-cognito-identity-js';
import createHmac from 'create-hmac';

/**
 * Creates the ChallengeResponses required for USER_SRP authentication
 * @param  {Object} authenticationHelper an initialised instance of amazon-cognito-identity-js.AuthenticationHelper
 * @param  {String} password             users password
 * @param  {Object} ChallengeParameters  the ChallengeParameters received back from initial SRP call
 * @return {Object}                      ChallengeResponses data to auth with
 */
export const createChallengeResponses = async (BigIntClass, authenticationHelper, password, ChallengeParameters) => {
    const dateHelper = new DateHelper();

    // There will either be USER_ID_FOR_SRP || USERNAME + DEVICE_KEY
    const { SRP_B, SALT, USER_ID_FOR_SRP, USERNAME, DEVICE_KEY, SECRET_BLOCK } = ChallengeParameters;
    const serverBValue = new BigIntClass(SRP_B, 16);
    const salt = new BigIntClass(SALT, 16);

    const getPasswordAuthenticationKey = promisify(authenticationHelper.getPasswordAuthenticationKey).bind(
        authenticationHelper
    );
    const hkdf = await getPasswordAuthenticationKey(USER_ID_FOR_SRP || DEVICE_KEY, password, serverBValue, salt);

    const dateNow = dateHelper.getNowString();
    const messageBuffer = Buffer.concat([
        Buffer.from(authenticationHelper.poolName, 'utf8'),
        Buffer.from(USER_ID_FOR_SRP || DEVICE_KEY, 'utf8'),
        Buffer.from(SECRET_BLOCK, 'base64'),
        Buffer.from(dateNow, 'utf8')
    ]);
    const keyBuffer = Buffer.from(hkdf);
    const signatureString = createHmac('sha256', keyBuffer).update(messageBuffer).digest('base64');

    const ChallengeResponses = {};

    ChallengeResponses.USERNAME = USER_ID_FOR_SRP || USERNAME;
    ChallengeResponses.PASSWORD_CLAIM_SECRET_BLOCK = SECRET_BLOCK;
    ChallengeResponses.TIMESTAMP = dateNow;
    ChallengeResponses.PASSWORD_CLAIM_SIGNATURE = signatureString;
    if (DEVICE_KEY) {
        ChallengeResponses.DEVICE_KEY = DEVICE_KEY;
    }

    return ChallengeResponses;
};

/**
 * Wrapper to make authenticationHelper.getLargeAValue less verbose
 * @param  {Object} authenticationHelper an initialised instance of amazon-cognito-identity-js.AuthenticationHelper
 * @return {String}                      string representation of a large A value
 */
export const createLargeAValue = async authenticationHelper => {
    return new Promise((resolve, reject) => {
        authenticationHelper.getLargeAValue((errOnAValue, aValue) => {
            if (errOnAValue) {
                reject(errOnAValue);
            }

            resolve(aValue.toString(16));
        });
    });
};

export const getUserContextData = (username, poolId, clientId) => {
    // AmazonCognitoAdvancedSecurityData comes from a AWS JS script loaded in the html head
    if (typeof AmazonCognitoAdvancedSecurityData === 'undefined') {
        return undefined;
    }
    /* eslint-disable */
    const amazonCognitoAdvancedSecurityDataConst = AmazonCognitoAdvancedSecurityData;
    /* eslint-enable */

    const advancedSecurityData = amazonCognitoAdvancedSecurityDataConst.getData(username, poolId, clientId);
    if (advancedSecurityData) {
        const userContextData = {
            EncodedData: advancedSecurityData
        };
        return userContextData;
    }

    return {};
};
