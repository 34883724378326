import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { changeHandler, validationHandler, validationResetHandler } from '../lib/form';
import {
    ScreenWrapper,
    InputWrapper,
    Input,
    ErrorContainer,
    Error,
    Submit,
    Label,
    TextHeader,
    SubmitWrapper,
    SubText,
    StatusCircle,
    CheckIcon,
    CheckBackIconPath,
    TextHeaderMedium,
    ButtonsWrapper
} from '../styles';

const SearchParams = new URLSearchParams(window.location.search);

const ResetPassword = ({
                           match: {
                               params: { token }
                           }
                       }) => {
    const history = useHistory();
    const [complete, setComplete] = React.useState(false);
    const [email, setEmail] = React.useState(SearchParams.get('email'));
    const [emailError, setEmailError] = React.useState(false);
    const [code, setCode] = React.useState(SearchParams.get('code'));
    const [codeError, setCodeError] = React.useState(false);
    const [password, setPassword] = React.useState(null);
    const [passwordError, setPasswordError] = React.useState(false);
    const [password2, setPassword2] = React.useState(null);
    const [password2Error, setPassword2Error] = React.useState(null);
    const [error, setError] = React.useState(null);

    const isPrepopulated = SearchParams.get('email') && SearchParams.get('code');

    const errorHandlers = {
        password: setPasswordError,
        password2: setPassword2Error,
        code: setCodeError,
        email: setEmailError
    };

    const handleChange = changeHandler({
        password: setPassword,
        password2: setPassword2,
        code: setCode,
        email: setEmail
    });
    const handleValidation = validationHandler(errorHandlers);
    const handleValidationReset = validationResetHandler(errorHandlers);

    const resetErrors = () => {
        setEmailError(false);
        setPasswordError(false);
        setPassword2Error(false);
        setCodeError(false);
        setError(null);
    };

    const handleSubmit = async event => {
        event.preventDefault();

        if (password !== password2) {
            setError('Passwords don\'t match!');
            return;
        }

        if (password.length < 8) {
            setError('Password must be 8 characters or more');
            return;
        }

        if (!password.match(/\d/)) {
            setError('Password must include a number');
            return;
        }

        try {
            const res = await fetch(`/auth/reset-password`, {
                method: 'POST',
                body: JSON.stringify({ code, email, password })
            });

            if (res.status === 200) {
                setComplete(true);
            } else if (res.status === 400) {
                setError('Invalid Password. Please try again');
            } else if (res.status === 401) {
                setError('Invalid token');
            } else {
                setError('An unexpected error occurred. Please try again');
            }
        } catch (err) {
            setError('An unexpected error occurred. Please try again');
        }
    };

    const goHome = () => history.push(`/`);

    const resetError = () => {
        setError(null);
    };

    return (
        <ScreenWrapper>
            {error && (
                <ErrorContainer>
                    <Error onClick={resetError}>{error}</Error>
                </ErrorContainer>
            )}
            {complete && (
                <div>
                    <StatusCircle>
                        <CheckIcon viewBox="0 0 24 24">
                            <CheckBackIconPath />
                        </CheckIcon>
                    </StatusCircle>
                    <TextHeaderMedium>
                        <span>That’s great, you have successfully reset your password</span>
                    </TextHeaderMedium>
                    <SubText>
                        <span>
                            <b>Tip:</b> To keep your account as secure as possible, turn on Two Factor Authentication by
                            visiting the Account Security section in the Hive App.
                        </span>
                    </SubText>
                    <ButtonsWrapper>
                        <Submit onClick={goHome} type="button" value="Go to login" isSecondary fluidWidth />
                    </ButtonsWrapper>
                </div>
            )}
            {!complete && (
                <div>
                    <TextHeader>
                        <span>Please enter a new password</span>
                    </TextHeader>
                    <SubText>
                        <span>
                            Choose a password that has a minimum of <b>8 characters</b> and at least <b>one number</b>.
                        </span>
                    </SubText>
                    <SubText>
                        <span>
                            For additional security, use a mixture of uppercase, lowercase, numbers and special
                            characters. Please don’t repeat previous passwords.
                        </span>
                    </SubText>
                    <form onSubmit={handleSubmit} onInvalid={handleValidation} onChange={handleValidationReset}>
                        <InputWrapper isInvisible={isPrepopulated}>
                            <Label isError={emailError} htmlFor="email">
                                Email Address:
                            </Label>
                            <Input
                                isError={emailError}
                                name="email"
                                value={email}
                                onChange={handleChange}
                                type="email"
                                required
                            />
                        </InputWrapper>
                        <InputWrapper isInvisible={isPrepopulated}>
                            <Label isError={codeError} htmlFor="code">
                                Code:
                            </Label>
                            <Input
                                isError={codeError}
                                name="code"
                                value={code}
                                onChange={handleChange}
                                type="string"
                                pattern="[0-9]{6}"
                                title="6 digit code"
                                required
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Label isError={passwordError} htmlFor="password">
                                New password:
                            </Label>
                            <Input
                                isError={passwordError}
                                name="password"
                                onChange={handleChange}
                                type="password"
                                required
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Label isError={password2Error} htmlFor="password2">
                                Re-enter new password:
                            </Label>
                            <Input
                                isError={password2Error}
                                name="password2"
                                onChange={handleChange}
                                type="password"
                                required
                            />
                        </InputWrapper>
                        <SubmitWrapper>
                            <Submit onClick={resetErrors} type="submit" value="Submit" />
                        </SubmitWrapper>
                    </form>
                </div>
            )}
        </ScreenWrapper>
    );
};

export default ResetPassword;
